.file-modal {
    &__container {
        padding: 20px 40px;
    }

    &__uploader-wrapper {
        position: relative;
        width: 200px;
        height: 32px;
    }

    &__upload {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    &__upload-btn {
        line-height: 31px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        margin-top: 10px;
        padding-left: 11px;
    }

    &__indicator {
        position: absolute;
        left: calc(100% + 10px);
        bottom: 0;
    }

    &__attachment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        color: #4497cb;
    }

    &__close-btn {
        cursor: pointer;
    }

    &__error-label {
        color: #f30;
        margin: 10px 0 0 2px;
    }

    &__btn {
        margin-top: 20px;
    }
}
