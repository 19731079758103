.submit-result {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__result-title {
        color: #222;
        font-size: 27px;
        margin-top: 20px;
        margin-bottom: 5px;
        text-align: center;
    }

    &__result-desc {
        font-size: 14px;
        text-align: center;
    }
}
