body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}

.form-container {
    padding: 12px 20px 20px !important;
}
