.session-modal {
    padding: 5px 50px 15px;

    &__row {
        display: flex;
        align-items: center;
    }

    &__column {
        flex: 1;
    }

    &__time-picker {
        width: 100%;
    }

    &__track-dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
    }

    &__file-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__file-name {
        margin: 0;
        color: #000;
        font-weight: 500;
    }

    &__file-icon {
        padding: 3px;
        margin: 0 2px;
        cursor: pointer;
        color: #4497cb;
    }
}
