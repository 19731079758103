.avatar-selector {
  $size: 80px;
  width: $size;
  height: $size;
  display: inline-block;
  position: relative;

  &__container {
    width: $size;
    height: $size;
    overflow: hidden;

    &_square {
      border-radius: 0;
    }

    &_circle {
      border-radius: 50%;
    }
  }

  &:hover {
    .avatar-selector__overlay {
      opacity: 1;
    }
  }

  &__image {
    width: $size;
    height: $size;
    object-fit: contain;
  }

  &__overlay {
    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 24px;
    transition: all 300ms;
    opacity: 0;
  }

  &__upload-indicator {
    position: absolute;
    bottom: 0;
    right: -5px;
  }

  &__upload-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
  }
}
