@import '../../base';

.login {
    background-color: $page-color;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__footer {
        position: absolute;
        width: 100%;
        height: 400px;
        background: url('../../asset/img/footer-cloud.png') center bottom repeat-x;
        bottom: 0;
    }

    &__card-container {
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(171, 198, 235, 0.3);
        display: flex;
        margin-bottom: 50px;
        padding: 60px 20px;
        position: relative;
        width: 700px;
        z-index: 1;
    }

    &__block-left {
        width: 330px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__block-right {
        width: 370px;
    }

    &__logo {
        display: block;
        width: 100px;
        margin: 0 auto;
    }

    &__title {
        text-align: center;
        margin: 10px 0 0;
    }

    &__form {
        padding: 20px 30px;
    }

    &__icon {
        color: rgba(0, 0, 0, 0.25);
    }

    &__copyright {
        text-align: center;
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
    }
}
