.presenter-tag {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #e8e8e8;
    padding: 2px 5px;
    border-radius: 3px;
    user-select: none;
    margin: 1px 0 1px 5px;
    font-size: 13px;

    &__body {
        color: #222;
    }

    &__close-btn {
        cursor: pointer;
        margin-left: 4px;
        color: #6a6f7b;
    }
}
