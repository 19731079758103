.event-basic {
    padding-right: 190px;

    &__date-picker {
        width: 280px;
    }

    &__subtitle {
        margin: 0 0 2px;
        font-size: 12px;
        color: #777;
    }

    &__row {
        display: flex;
    }

    &__column {
        flex: 1;
    }

    &__location {
        flex: 1;
        margin-right: 15px;
        .ant-form-item {
            margin-bottom: 5px;
        }
    }

    &__check-btn {
        color: #4497cb;
        font-size: 13px;
        cursor: pointer;
        margin: 0;
        line-height: 25px;
        &:hover {
            color: #006699;
        }
    }

    &__upload-wrapper {
        position: relative;
        border: 2px solid #e8e8e8;
        margin-top: 10px;
        padding: 8px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__logo-upload-wrapper {
        width: 200px;
        height: 200px;
    }

    &__cover-upload-wrapper {
        width: 500px;
        height: 200px;
    }

    &__upload-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
    }

    &__logo-img {
        width: 100%;
        height: 100%;
    }

    &__upload-indicator {
        position: absolute;
        bottom: 0;
        left: calc(100% + 10px);
        font-size: 18px;
    }

    &__save-btn {
        width: 200px;
        margin-top: 30px;
    }

    &__input {
        margin: 10px 0;
    }
}
