.not-found {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
    }

    &__img {
        width: 200px;
        margin-right: 30px;
    }
}
