.session-item {
    border: 1px solid #eef2f6;
    padding: 10px 15px;
    position: relative;
    margin-bottom: 3px;

    &__tag {
        cursor: default;
        border-radius: 20px;
        margin-left: 10px;
        padding: 0 10px;
        user-select: none;
    }

    &__presenter-row {
        margin-bottom: 5px;
    }

    &__action-group {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    &__icon {
        padding: 3px;
        margin: 0 2px;
        color: #4497cb;
        cursor: pointer;
    }

    &__add-btn {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        margin-left: 15px;
        font-size: 12px;
        border: 1px dashed #9197a3;
        border-radius: 3px;
        padding: 1px 5px;
        cursor: pointer;
        transition: all 400ms;

        &:hover {
            border-style: solid;
        }
    }
}
