.pure-html {
    padding: 10px 15px;

    p {
        margin-bottom: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: initial;
    }
}
