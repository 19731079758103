.agenda-file-tag {
    font-size: 12px;
    margin-left: 20px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    &__add-btn {
        border: 1px dashed #9197a3;
        border-radius: 3px;
        padding: 1px 5px;
        cursor: pointer;
        transition: all 400ms;

        &:hover {
            border-style: solid;
        }
    }

    &__file {
        font-size: 13px;
        line-height: 13px;
        color: #f95740;
        font-weight: 600;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    &__btn {
        font-weight: 400;
        margin-left: 5px;
        font-size: 11px;
        line-height: 11px;
        cursor: pointer;
        color: #6a6f7b;
    }
}
