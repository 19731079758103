@import '../../../base';

.header {
    height: 64px;
    background-color: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);

    &__container {
        height: inherit;
        margin: 0 auto;
        max-width: $max-width;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__nav,
    &__menu {
        display: flex;
        align-items: center;
    }

    &__nav {
        margin-left: 20px;
    }

    &__logout {
        cursor: pointer;
        margin-left: 10px;
        color: $main-color;

        &:hover {
            color: lighten($main-color, 10%);
        }
    }

    &__nav-li {
        a {
            text-decoration: none;
            margin: 0 20px;
            font-size: 16px;
            font-weight: 500;
        }
    }

    &__right {
        display: flex;
        align-items: center;
    }

    &__avatar {
        border-radius: 50%;
        border: 1px solid #aaa;
        margin-right: 5px;
    }

    &__version {
        font-size: 9px;
        text-align: right;
        color: #aaa;
    }
}
