.agenda-group {
    border: 1px solid #e8e8e8;
    margin: 3px 0;
    &__title {
        padding: 3px 8px;
        border-bottom: 1px solid #e8e8e8;
        background-color: #eef2f6;
        font-weight: 600;
    }

    &__body {
        padding: 20px;
    }
}
