.track-modal {
    &__color-label {
        display: flex;
        align-items: center;
    }

    &__color-block {
        display: inline-block;
        width: 30px;
        height: 15px;
        margin-left: 10px;
    }
}
