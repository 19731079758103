@import '../../base';

.App {
    background-color: $page-color;
    min-height: 100vh;
    padding-top: 64px;

    &__container {
        max-width: $max-width;
        margin: 0 auto;
    }
}
