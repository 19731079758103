.track-table {
    border: 1px solid #e8e8e8;
    background-color: #fff;

    &__color-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
    }

    .ant-table-pagination {
        margin-right: 20px;
    }

    &__icon {
        padding: 3px;
        cursor: pointer;
        margin: 0 5px;
    }
}
