.event-detail {
    padding: 15px 0 25px;
    display: flex;
    align-items: flex-start;
    &__menu {
        width: 200px;
    }

    &__main {
        flex: 1;
        margin-left: 10px;
        background-color: #fff;
        padding: 30px 40px 50px 40px;
        border: 1px solid #e8e8e8;
    }

    &__toolbar {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 10px;
    }
}
