@import '../../base';

.required-title {
    color: $main-color;
    margin: 0 0 3px;

    &__star {
        color: #f30;
        margin-left: 3px;
    }
}
