.event-sponsor {
    //padding-right: 170px;

    &__upload-wrapper {
        position: relative;
        border: 2px solid #e8e8e8;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 8px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 200px;
    }

    &__upload-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
    }

    &__upload-indicator {
        position: absolute;
        bottom: 0;
        left: calc(100% + 10px);
        font-size: 20px;
    }

    &__delete-btn {
        position: absolute;
        top: 0;
        left: calc(100% + 10px);
        color: #9197a3;
        cursor: pointer;
    }

    &__sponsor-list {
        display: flex;
        flex-wrap: wrap;
    }
}
